#app {
  display: grid;
  grid: 1fr auto / 1fr 2fr 1fr;
  width: 100vw;
  height: 100vh;
  gap: 1px;
}

body {
  margin: auto;
}

#scene {
  grid-column: span 3;
  min-width: 400px;
  min-height: 200px;
  margin: auto;
}

aside {
  text-align: end;
  padding: 0 0.2em;
}
main ~ aside {
  text-align: start;
}

main {
  grid-column: 2;
  min-height: 6em;
  padding: 0.2em;
  border: medium solid var(--color);
  border-bottom: none;
  background-color: var(--background-color);
}

h1 {
  grid-column: span 3;
  margin: auto;
}

button {
  font-size: inherit;
}

/* Minimum aspect ratio */
@media (min-aspect-ratio: 21/9) {
  #scene,
  main,
  h1 {
    grid-column: auto;
  }
  #app {
    grid: 1fr/ 1fr auto auto auto;
  }
  main {
    border-top: none;
    width: 25em;
    max-width: 25vw;
    height: calc(100% - 0.4em);
  }
  main:last-child {
    border-right: none;
  }
}

/* Maximum aspect ratio */
@media (max-aspect-ratio: 3/2) {
  #app {
    grid: 1fr auto auto / 1fr;
  }
  #scene,
  main,
  h1 {
    grid-column: auto;
  }
  main {
    border-left: none;
    border-right: none;
  }
  main:not(last-child) {
    border-bottom: medium solid var(--color);
  }
  aside > * {
    display: inline-block;
  }
}

/* Maximum aspect ratio */
@media (max-aspect-ratio: 2/3) {
  #scene,
  main,
  h1 {
    grid-column: auto;
  }
  #app {
    grid: auto / 1fr;
    height: auto;
  }
  main {
    border-left: none;
    border-right: none;
    border-bottom: medium solid var(--color);
  }
}
